import RemoveDevJob from "./RemoveDevJob";

function DevJobRow({devJob}) {
	return (
		<tr>
			<td>{devJob.name}</td>
			<td>{devJob.price}</td>
			<td>{devJob.freelancer?.fullName}</td>
			<td>{devJob.description}</td>
			<td>
				{devJob.invoiced ? "This job is already invoiced." :
					<div className={"d-flex gap-2"}>
						<RemoveDevJob devJob={devJob}/>
					</div>
				}
			</td>
		</tr>
	);
}

export default DevJobRow;