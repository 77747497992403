import SortableHeader from "../../../SortableHeader";
import {useContext, useEffect, useState} from "react";
import resize from "../../../../util/resize";
import Pagination from "../../../Pagination";
import DevJobRow from "./DevJobRow";
import jobContext from "../../../../contexts/jobs/jobContext";
import Spinner from "../../../Spinner";

function DevJobs() {
	const {jobs, loading} = useContext(jobContext);

	useEffect(() => {
		const items = [];

		try {
			resize(items);
		}
		catch (e) {
			console.error(e);
		}

		return () => {
			for (let item of items) {
				item.element.removeEventListener("mousedown", item.handler);
			}
		}
	}, []);

	return (
		<div className={"table-responsive overflow-auto"}>
			<table className={"table table-striped table-bordered mt-2"} id={"resizeMe"}>
				<thead className={"table-light"}>
				<tr>
					<th>Name</th>
					<th>Value</th>
					<th>FL with DEV WT</th>
					<th>Description</th>
					<th>Actions</th>
				</tr>
				</thead>
				<tbody>
				{loading && <tr><td colSpan={5}><Spinner /></td></tr>}

				{jobs.map(value => {
						return <DevJobRow devJob={value} key={value.id}/>;
					})}
				</tbody>
			</table>
		</div>
	)
}

export default DevJobs;